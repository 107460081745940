import React from 'react';
import Hero from './Hero';
import Highlights from './Highlights';
import Model from './Model';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Footer from './Footer';
import { emailImg } from '../utils';
import Navbarr from './Navbarr';


const Terms = () => {
  return (
    <section className='bg-blak'>
            <div className='bg-blaklz'>
      </div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>

  <p id="hero" style={{fontSize:"60px"}} class="hero-title">Terms and Conditions <br></br></p>
  <br></br>
  <p style={{ color: 'grey' }} className="text-white text-center heio"> 
  Date of Last Revision: 8th July 2024
</p>
  <div className='space'></div>
  <div className='space'></div>

  <p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
        Welcome to our website, these terms and conditions outline the rules and regulations for the use of Futuro's Website, located at https://www.getfuturo.com.<br></br>
        By accessing this website we assume you accept these terms and conditions. <br></br> Do not continue to use our website's services if you do not agree to take all of the terms and conditions stated on this page.
<br></br> 
<br></br>
<br></br>

The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, <br/> the person log on this website and compliant to Futuro Technologies' terms and conditions. <br/>
 "Futuro Technologies", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms <br></br> refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting <br></br> the Client's needs in respect of provision of Futuro Technologies' stated services, in accordance with and subject to, prevailing law of us. Any use of the above terminology or other words <br/> in the singular, plural, capitalization and/or he/she or they, <br/>
 are taken as interchangeable and therefore as referring to same.
</p>
<br></br> 
<br></br>
<br></br>
<br></br>

      
<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Cookies <br></br></p>
<br></br>

<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
We employ the use of cookies. By accessing Futuro Website, you agreed to use cookies in agreement with the Futuro's Privacy Policy. <br className='desktop-only' ></br>

Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. <br/> Some of our affiliate/advertising partners may also use cookies.
</p>
<br></br> 
<br></br>
<br></br>
<br></br>


<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">License <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
Unless otherwise stated, Futuro and/or its licensors own the intellectual property rights for all material on Futuro Website. All intellectual property rights are reserved. <br className='desktop-only' ></br> You may access this from Futuro Website for your own personal use subjected to restrictions set in these terms and conditions. <br></br>

You must not:

Republish material,
sell, rent or sub-license material, 
reproduce, duplicate or copy material or
redistribute content from Futuro's Website
<br></br>
<br></br>
This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.

Parts of this website offer an opportunity for users <br className='desktop-only' ></br>to post and exchange opinions and information in certain areas of the website. Futuro does not filter, edit, publish or review Comments prior to their presence on the website. <br></br> Comments do not reflect the views and opinions of Futuro,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted <br className='desktop-only' ></br> by applicable laws, Futuro shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of <br/> any use of and/or posting of and/or appearance of the Comments on this website.
<br></br>
Futuro reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions:
<br></br>
<br></br>
You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;
<br></br>
The Comments should not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;
<br></br>

The Comments should not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy
<br></br>

The Comments should not be used to solicit or promote a third party, non-affiliated business, or unlawful activity.
<br></br>

</p>
<br></br> 
<br></br>
<br></br>
<br></br>

<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Content Liability <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
We shall not be hold responsible for any content that appears on your website. You agree to protect and defend us against all claims that is rising on your website. <br></br> No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights. <br></br>

</p>
<br></br> 
<br></br>
<br></br>
<br></br>


<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Reservation of Rights <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. <br></br> We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions. <br></br>

</p>
<br></br> 
<br></br>
<br></br>
<br></br>

<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Termination <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
You agree that Futuro, Futuro has the authority to suspend or terminate your account or use of the Service, as well as delete any content within the Service, at its discretion and for any reason. <br></br> This includes situations where your account is inactive or if Futuro believes you have violated the terms outlined in the Terms of Service. If there is any suspected fraudulent, abusive, <br></br> or illegal activity, Futuro may report it to the appropriate law enforcement authorities. Futuro also reserves the right to discontinue the Service, or any part of it, at any time and without prior notice. <br/> You acknowledge that termination of your access to the Service may occur <br/> without prior notice, and you agree that Futuro may deactivate or delete your account, as well as any associated information and <br></br> files, and prevent further access to them. It's important to note that Futuro will not be held liable to you or any third party for any termination of your access to the Service. <br></br> We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions. <br></br>

</p>
<br></br> 
<br></br>
<br></br>
<br></br>

<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">User Disputes <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
You acknowledge that you bear full responsibility for your interactions with other users while using the Service, and Futuro will not be held liable or responsible for any issues arising from those <br></br> interactions. Futuro reserves the right, but is not obligated, to intervene in any disputes between you and other users of the Service.


</p>
<br></br> 
<br></br>
<br></br>
<br></br>

<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Commercial Use <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
Except when expressly authorized by Futuro in writing, you agree not to engage in the following activities with regard to the Service: <br></br> displaying, distributing, licensing, performing, publishing, reproducing, duplicating, <br/> copying, creating derivative works from, modifying, selling, reselling, exploiting, transferring,  or uploading for any commercial purposes.  <br/> The Service is intended for your personal, non-commercial use for entertainment purposes only.


</p>
<br></br> 
<br></br>
<br></br>
<br></br>

<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Fees <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
If the Service or any part of it is available for a fee, you will be required to select a payment plan and provide Futuro with your credit card or other payment instrument information. By doing so, <br></br>  you represent and warrant that the provided information is accurate and that you are authorized to use the payment instrument. <br></br> It is your responsibility to promptly update your account information if any changes occur, such as a change in billing address or credit card expiration date. <br></br>  You agree to pay Futuro the specified amount outlined in the payment plan, along with any applicable taxes, in accordance with the terms of the plan and this Terms of Service. <br></br> 

By authorizing Futuro, you give consent for them to charge your payment instrument as per the terms of the applicable payment plan, including any applicable taxes, until you terminate your account. <br></br>  You also agree to pay for any charges incurred. In case of any disputes regarding charges, you must notify Futuro within <br></br>  sixty (60) days from the date of the charge. Futuro reserves the right to change its prices, <br></br>  and your continued use of the Service after the price change takes effect signifies your agreement to pay the revised amount. <br></br>  You are responsible for any taxes related to the Services, except for U.S. taxes based on Futuro's net income.

</p>
<br></br> 
<br></br>
<br></br>
<br></br>


<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Recurring Subscriptions <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
If you choose a Service with an auto-renewal feature (a "Recurring Subscription"), you give Futuro permission to store your account information and automatically charge that account upon the renewal <br></br> of the chosen Service without requiring any further action from you.  In the event that Futuro is unable to charge your account as authorized during the enrollment of a Recurring Subscription, <br></br> Futuro, at its sole discretion, may take the following actions: <br></br> (i) bill you for the Service and suspend your access until payment is received, and/or (ii) attempt to update your account information through third-party sources <br></br> (such as your bank or a payment processor) to continue charging your account as authorized. <br></br>

Futuro may modify the price for Recurring Subscriptions from time to time and will inform you of any price changes in advance, along with instructions on how to accept those changes, <br></br> if applicable. Changes in price for Recurring Subscriptions will come into effect at the beginning of the next subscription period following the date of the price change. <br></br> In accordance with local laws, you accept the new price by continuing to use your Recurring Subscription after the price change takes effect. If you do not agree with the price changes, you have the <br></br> right to reject the change by canceling your Recurring Subscription before the price change becomes effective. It is important to carefully review any notifications regarding price changes.

</p>
<br></br> 
<br></br>
<br></br>
<br></br>

<p id="hero" style={{fontSize:"40px", color: "white"}} class="hero-title">Any Questions? <br></br></p>
<br></br>


<p style={{ color: 'grey' }} className="text-white text-centerr heio"> 
If you have any questions about our Terms and Conditions, you may contact us by email: business@getfuturo.com

</p>
<br></br> 
<br></br>
<br></br>
<br></br>

    </section>
  );
};



export default Terms;
