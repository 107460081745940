import hero from "/assets/images/hero.jpeg";

export const heroImg = hero;

import imgg from "/assets/images/quick.png";

export const imggImg = imgg;

import aimgg from "/assets/images/mock.png";

export const aimggImg = aimgg;

import eimgg from "/assets/images/community.png";

export const eimggImg = eimgg;

import veriimgg from "/assets/images/verified.png";

export const veriimggImg = veriimgg;

import veroimgg from "/assets/images/futuroll.png";

export const veroimggImg = veroimgg;

import courseimgg from "/assets/images/courses.png";

export const courseImg = courseimgg;

import arrowimgg from "/assets/images/arrow.svg";

export const arrowImg = arrowimgg;


import futuroimgg from "/assets/images/logo.png";

export const futuroImg = futuroimgg;


import communityimgg from "/assets/images/communityy.png";

export const communityImg = communityimgg;


import firstneed from "/assets/videos/NEED-1.mp4";

export const firstneedd = firstneed;

import secondneed from "/assets/videos/NEED-2.mp4";

export const secondneedd = secondneed;

import thirdneed from "/assets/videos/NEED-3.mp4";

export const thirdneedd = thirdneed;

import fourthneed from "/assets/videos/NEED-4.mp4";

export const fourthneedd = fourthneed;

import fifthneed from "/assets/videos/NEED-5.mp4";

export const fifthneedd = fifthneed;

import sixthneed from "/assets/videos/NEED-6.mp4";

export const sixthneedd = sixthneed;

import seventhneed from "/assets/videos/NEED-7.mp4";

export const seventhneedd = seventhneed;

import emailimgg from "/assets/images/email.svg";

export const emailImg = emailimgg;

















import verrimgg from "/assets/images/verr.png";

export const verImg = verrimgg;




import removeeimgg from "/assets/images/remove.png";

export const removeImg = removeeimgg;



import massiveeimgg from "/assets/images/massive.png";

export const massiveImg = massiveeimgg;


import secondAdmgg from "/assets/images/lk.png";

export const secondAdImg = secondAdmgg;





import franceAdmgg from "/assets/images/france.jpg";

export const franceAdImg = franceAdmgg;


import ukAdmgg from "/assets/images/uk.svg";

export const ukAdImg = ukAdmgg;







import hmv from "/assets/videos/hero.mp4";
import smallmv from "/assets/videos/smallHero.mp4";
import highlightFirstmv from "/assets/videos/highlight-first.mp4";
import highlightSectmv from "/assets/videos/hightlight-third.mp4";
import highlightThirdmv from "/assets/videos/hightlight-sec.mp4";
import highlightFourthmv from "/assets/videos/hightlight-fourth.mp4";
import exploremv from "/assets/videos/explore.mp4";
import framemv from "/assets/videos/frame.mp4";

import apple from "/assets/images/apple.svg";
import Ad from "/assets/videos/ad.mp4";
import search from "/assets/images/search.svg";
import bag from "/assets/images/bag.svg";
import watch from "/assets/images/watch.svg";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";

import yellow from "/assets/images/yellow.jpg";
import blue from "/assets/images/blue.jpg";
import white from "/assets/images/white.jpg";
import black from "/assets/images/black.jpg";
import explore1 from "/assets/images/explore1.jpg";
import explore2 from "/assets/images/explore2.jpg";
import chip from "/assets/images/chip.jpeg";
import frame from "/assets/images/frame.png";

export const heroVideo = hmv;
export const adVideo = Ad;
export const smallHeroVideo = smallmv;
export const highlightFirstVideo = highlightFirstmv;
export const highlightSecondVideo = highlightSectmv;
export const highlightThirdVideo = highlightThirdmv;
export const highlightFourthVideo = highlightFourthmv;
export const exploreVideo = exploremv;
export const frameVideo = framemv;

export const appleImg = apple;
export const searchImg = search;
export const bagImg = bag;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

export const yellowImg = yellow;
export const blueImg = blue;
export const whiteImg = white;
export const blackImg = black;
export const explore1Img = explore1;
export const explore2Img = explore2;
export const chipImg = chip;
export const frameImg = frame;
