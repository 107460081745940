import { useGSAP } from "@gsap/react"
import gsap from "gsap"
import { rightImg, watchImg } from "../utils"

import VideoCarousel from './VideoCarousel';

import {React, useRef, useState} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { adVideo } from "../utils";



const Highlights = () => {

  const videoRef = useRef(null);
  const [showResumeIcon, setShowResumeIcon] = useState(true);

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setShowResumeIcon(false);
    } else {
      videoRef.current.pause();
      setShowResumeIcon(true);
    }
  };


  useGSAP(() => {
    gsap.to('#title', { opacity: 1, y: 0 })
    gsap.to('.link', { opacity: 1, y: 0, duration: 1, stagger: 0.25 })
  }, [])

  return (
    
    <div className="bg-trans">

<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>

    <section id="highlights" className="bg-blackk w-screen overflow-hidden h-full common-padding">
      <div className="screen-max-width">
        <div className="mb-12 w-full md:flex items-end justify-between">

        </div>

          




    <div style={{ position: 'relative' }}>
      <video
        ref={videoRef}
        playsInline
        className="pointer-events-none"
        preload="auto"
        muted
        loop
        onClick={handleVideoClick}
      >
        <source src={adVideo} type="video/mp4" />
      </video>

      {showResumeIcon && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faPlay}
            size="3x"
            style={{ cursor: 'pointer' }}
            onClick={handleVideoClick}
          />
        </div>
      )}
    </div>

    </div>




    </section>
    </div>
  )
}

export default Highlights