import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Navbarr from './components/Navbarr';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import Model from './components/Model';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import Footer from './components/Footer';
import WhoPage from './components/Who';
import About from './components/About';
import Help from './components/Help';
import Featuring from './components/Featuring';
import WaitingListForm from './components/WaitingListForm';
import Loading from './components/Loading';
import * as Sentry from '@sentry/react';
import VideoCarousel from './components/VideoCarousel';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Money from './components/Money'
import Soon from './components/Soon';

const App = () => {
  const currentPath = window.location.pathname;

  return (
    
    <div className="container" id="scrollableContainer">
      
      {currentPath === '/' ? (
        <>

          <Navbar />

          <Hero />
          <Model />
          <div className="flex-center sm:px-10">
            <div className="video-carousel-container">
              <VideoCarousel />
            </div>
          </div>
          <Money />
          <Features />
          <HowItWorks />
          <Footer />

        </>
      ) : currentPath === '/who' ? (
        <>
          <Navbarr />
          <WhoPage />
        </>
      ) : currentPath === '/features' ? (
        <>
                  <Navbarr />
          <Soon />
        </>
      ) : currentPath === '/about' ? (
        <>
                  <Navbarr />
          <Soon />
        </>
      ) : currentPath === '/support' ? (
        <>
          <Help />
        </>
      ) : currentPath === '/waitinglist' ? (
        <>
          <WaitingListForm />
        </>
      ) : currentPath === '/privacy' ? (
        <>
          <Privacy />
          <Footer />
        </>
      ) : currentPath === '/terms' ? (
        <>
          <Terms />
          <Footer />
        </>
      ) : (
        <>
          <Highlights />
          <Model />
          <Features />
          <HowItWorks />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Sentry.withProfiler(App);