import React, { useRef } from 'react'
import { chipImg, frameImg, frameVideo } from '../utils'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap';
import { animateWithGsap } from '../utils/animations';

const HowItWorks = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.from('#chip', {
      scrollTrigger: {
        trigger: '#chip',
        start: '20% bottom'
      },
      opacity: 0,
      scale: 2,
      duration: 2,
      ease: 'power2.inOut'
    })

    animateWithGsap('.g_fadeIn', {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.inOut'
    })
  }, []);

  return (
    <section className="bg-blackk common-padding">
      <div className="screen-max-width">
        <div id="chip" className="flex-center w-full my-20">
          <img src={chipImg} alt="chip" width={180} height={180} />
        </div>

        <div className="flex flex-col items-center">
          <h2 style={{fontFamily: "YES"}} className="hiw-title">
            Take action and join
            <br /><span style={{fontFamily: "YES", color: '#00FFFF' }}>Futuro </span>
          </h2>

<br></br>

          <p style={{fontFamily: "YES"}} className="hiw-subtitle">
          As {' '}
                    a teen,                    <span style={{fontFamily: "YES"}} className="hiw-subtitle "> time is your most<span style={{fontFamily: "YES", color: '#00FFFF' }}> valuable </span> resource, we will help you use it wisely
                    </span>.          </p>
        </div>



   
                <div className="">
                  

              </div>
              <div className='space'></div>
              <a href="https://getfuturo.com/features" rel="noopener noreferrer">

              <button style={{fontFamily: "YES"}} class="sleek-button">Get Started</button>
              </a>

            </div>
    </section>
  )
}

export default HowItWorks