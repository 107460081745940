import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { arrowImg } from '../utils';
import ReCAPTCHA from 'react-google-recaptcha';

const WaitingListForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const form = useRef();
  const recaptchaRef = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const emailValue = form.current.user_email.value;
    const nameValue = form.current.user_email.value;
    const messageValue = "Hello! Thank you so much for your interest in Futuro. During this stage of development, it is important that we make sure Futuro is tailored to it's best shape. Meanwhile, keep an eye on our website as we will constantly update it with news on the development of our product as well as new features!  ";

    const serviceId = 'service_tuib68k';
    
    const templateId = 'template_irzlb3i';

    const token = recaptchaRef.current.getValue();     
    
    const publicKey = '-LjV2JFPrYRftk1I6';


    const templateParams = {
         from_name: nameValue,
         from_email: "business@getfuturo.com",
         to_name: emailValue,
         message: messageValue,
         to_mail: emailValue,
      'g-recaptcha-response': token, // Include the reCAPTCHA token
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then(
        () => {
          setName('');
          setEmail('');
          setMessage('');
        },
        (error) => {
        }
      );
  };

  return (
  <div className='bg-black' style={{ marginTop: '-50px' }}>

  <form ref={form} onSubmit={sendEmail} className='form'>
    <div className='input-wrapper'>
    <input
  type='email'
  name='user_email'
  value={email}
  onChange={(e) => setEmail(e.target.value)}
  placeholder='Your Email Address:'
  onFocus={(e) => (e.target.placeholder = '')}
  onBlur={(e) => (e.target.placeholder = 'Your Email Address:')}
  className='input'
/>
<div style={{marginTop: "5px", fontFamily: "YES"}} className='px-2 text-sm cursor-pointer text-gray hover:text-white transition-all'> By signing up, you agree to our {''}
  <a  style={{  textDecoration: 'underline', fontFamily: "YES" }} href='/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a> and {''}
  <a  style={{  textDecoration: 'underline', fontFamily: "YES" }} href='/policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
</div>
<div className='space'></div>



      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey='6LfBbcQpAAAAACBlfC6x6YrfPIdeIB_nUyyMQgV9'
        onChange={() => {}}
        className='captcha'
      />
      
      <button type='submit' className='button'>
        <img src={arrowImg} alt='Arrow' className='input-icon' />
      </button>
    </div>
  </form>
  <div class="space"></div>
  <div class="space"></div>
  <div class="space"></div>
  <div class="space"></div>
  <div class="space"></div>
</div>
  );
};

export default WaitingListForm;