import { useGSAP } from "@gsap/react"
import gsap from "gsap";
import ModelView from "./ModelView";
import { useEffect, useRef, useState } from "react";
import { yellowImg, aimggImg, firstneedd, secondneedd,thirdneedd } from "../utils";

import * as THREE from 'three';
import { Canvas } from "@react-three/fiber";
import { View } from "@react-three/drei";
import { models, sizes } from "../constants";
import { animateWithGsapTimeline, animateWithGsap } from "../utils/animations";
import "..//idk"



const Model = () => {
  const [size, setSize] = useState('small');
  const [model, setModel] = useState({
    title: 'Simulating Futuro on iPhone 15 Pro in Natural Titanium',
    color: ['#8F8A81', '#FFE7B9', '#6F6C64'],
    img: yellowImg,
  })

  // camera control for the model view
  const cameraControlSmall = useRef();
  const cameraControlLarge = useRef();

  // model
  const small = useRef(new THREE.Group());
  const large = useRef(new THREE.Group());

  // rotation
  const [smallRotation, setSmallRotation] = useState(0);
  const [largeRotation, setLargeRotation] = useState(0);

  const tl = gsap.timeline();

  useEffect(() => {
    if(size === 'large') {
      animateWithGsapTimeline(tl, small, smallRotation, '#view1', '#view2', {
        transform: 'translateX(-100%)',
        duration: 2
      })
    }

    if(size ==='small') {
      animateWithGsapTimeline(tl, large, largeRotation, '#view2', '#view1', {
        transform: 'translateX(0)',
        duration: 2
      })
    }
  }, [size])

  useGSAP(() => {
  }, []);
    
  const videos = [firstneedd, secondneedd, thirdneedd];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [videos.length]);


  return (
    <div>
      <div className="baitspace"></div>
      <div className="baitspace"></div>
      <div className="baitspace"></div>

    <section className="bg-black h-full common-padding relative overflow-hidden">
      <div className="screen-max-wdith">
        
        <div className="flex flex-col justify-center items-center overflow-hidden">
          <div className="mt-32 mb-24 pl-55">
            <h2 style={{fontFamily: "YES"}} className="text-5xl lg:text-7xl font-semibold  text-center">Discover a platform<br></br>like no <span style={{ color: '#00FFFF' }}> other.  </span></h2>
            <br></br>
            <br></br>

            <p className="text-white text-center heio">
  Futuro is all about productivity and shaping our teens for the future. Discover below a few of our features: <br className="desktop-only" />
  <br /> </p>
          </div>


            </div>
          </div>
    </section>
    </div>
  )
}
export default Model