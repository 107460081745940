import React from 'react';
import Navbar from './Navbar';
import Hero from './Hero';
import Highlights from './Highlights';
import Model from './Model';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Footer from './Footer';

const Who = () => {
  return (
    <section className='bg-black'>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>

  <p id="hero" class="hero-title">This page is<br></br>under construction.</p>

    </section>
  );
};



export default Who;