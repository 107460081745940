import { useGSAP } from '@gsap/react'
import React, { useRef, useState} from 'react'
import { animateWithGsap } from '../utils/animations';
import { secondAdImg } from '../utils';


  const Money = () => {
  const videoRef = useRef();

  useGSAP(() => {

    animateWithGsap('#features_title', { y:5, opacity:0.5})
    animateWithGsap(
      '.g_grow',
      { scale: 1, opacity: 1, ease: 'power1' },
      { scrub: 5.5 }
    );
    animateWithGsap(
      '.g_texto',
      {y:0, opacity: 1,ease: 'power2.inOut',duration: 1}
    )
  }, []);











  return (
    <div>
    <section className="bg-black h-full common-padding relative overflow-hidden">
      <div className="screen-max-wdith">
        
        <div className="flex flex-col justify-center items-center overflow-hidden">
          <div className="mt-32 mb-24 pl-55">
          <span style={{ color: '#00FFFF' }}>  <h2 style={{fontFamily: "YES"}} className="text-5xl lg:text-7xl font-semibold">Monetize</h2></span>
            <h2 style={{fontFamily: "YES"}} className="text-5xl lg:text-7xl font-semibold">your expertise<br></br> </h2>
                        <br></br>
            <br></br>

            <p className="text-white text-center heio">
            Whether you're a creator or a user in Futuro, you have the ability to <br className='desktop-only' ></br> create your own income by encouraging productivity to others! 
  <br /> </p>
          </div>


          <div className="flex-center flex-col sm:px-10">
            <img src={secondAdImg} className="heeyy object-cover object-center" />

            <div className="flex flex-col w-full relative">
              <div className="feature-video-container">
              </div>
              <div className='space'></div>
          <div className='space'></div>
              <div className="feature-texto-container">
              <div className="flex-1 flex-center">

              </div>
              </div>
              </div>


  </div>
  
</div>
</div>




              




    </section>
    </div>
  )
}
export default Money