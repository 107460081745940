import React, { useEffect, useState } from 'react';
import { futuroImg } from '../utils';

const Soon = () => {




    return (
      <div className="bg-blakl loader">
          <p class="hero-titlepp">Coming soon. </p>
      </div>
    );
  }


export default Soon;