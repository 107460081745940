const fadeIns = document.querySelectorAll('.fade-in');

// Create an intersection observer
const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
      observer.unobserve(entry.target);
    }
  });
});

// Observe each fade-in element
fadeIns.forEach((fadein) => {
  observer.observe(fadein);
});