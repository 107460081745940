import { appleImg, bagImg, searchImg, franceAdImg, ukAdImg } from '../utils';
import { navLists, navref } from '../constants';
import {React, useState} from 'react';
const Navbar = () => {

  const [language, setLanguage] = useState('English');

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    // Add your logic here to handle language change, such as updating translations or redirecting to the corresponding language version of the website.
  };

  return (
    <div className='bg-black'>
      <header className="w-full py-5 sm:px-10 px-5 flex justify-between items-center sticky top-0 z-10 bg-black">
            <nav className="flex w-full screen-max-width">
          <a href="/">
            <img src="/assets/images/logo.png" alt="Apple" 
                width={window.innerWidth < 768 ? 120 : 100}
                height={window.innerWidth < 768 ? 120 : 100}
            />
          </a>
          <div className="flex flex-1 justify-center">
            {navLists.map((nav, index) => (
              <div
                key={nav}
                className="px-5 max-sm:px-4 text-sm cursor-pointer text-gray hover:text-white transition-all max-sm:mr-2 sm:mr-2"
                onClick={() => window.location.href = navref[index]}
              >
                {nav}
              </div>
            ))}
          </div>
          <div className="flex items-baseline">
            <div
              className={`cursor-pointer ${language === 'French' ? 'text-white' : 'text-gray'}`}
              onClick={() => handleLanguageChange('French')}
            >
              <a href='https://fr.getfuturo.com/' >
              <img
                src={franceAdImg}
                alt="French"
                className='flagss'
              />     
              </a>       </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;