import { useGSAP } from '@gsap/react'
import React, { useRef, useState} from 'react'
import { animateWithGsap } from '../utils/animations';
import { imggImg, communityImg } from '../utils';
import { arrowImg } from '../utils';


const Features = () => {
  const videoRef = useRef();

  useGSAP(() => {

    animateWithGsap('#features_title', { y:5, opacity:0.5})
    animateWithGsap(
      '.g_grow',
      { scale: 1, opacity: 1, ease: 'power1' },
      { scrub: 5.5 }
    );
    animateWithGsap(
      '.g_texto',
      {y:0, opacity: 1,ease: 'power2.inOut',duration: 1}
    )
  }, []);





  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndexs, setActiveIndexs] = useState(null);

  const questions = [
    {
      id: 1,
      question: 'What is Futuro?',
      answer: "Futuro is an emerging and reimagined social media platform that empowers the teens of today to bring their ideas and vision into reality.\n\nOur mission is to create a platform for our generation that differs from modern social media platforms. Through courses, a community tab and job listings, we created the ultimate productivity platform, in order to eliminate instant dopamine and time waste.",
    },
    {
      id: 2,
      question: 'What can i find in Futuro?',
      answer: 'With Futuro, you can access a wide range of features such as courses created by Verified Creators, Job listings, which allows our users to monetize their skills. \n\nWe also feature a community tab for users to collaborate, interact and network with others that are in the same path and share the same passion for success.',
    },
    {
      id: 3,
      question: 'Is Futuro available to use right now?',
      answer: 'At this stage, Futuro is under development as we prepare the core features for our platforms. We project to release a BETA for Futuro at the end of 2024.\n\nMeanwhile, you can sign up to our free waiting list below to be notified on when you can experience Futuro.',
    },
  ];

  const questionss = [
    {
      id: 4,
      question: "Are Futuro's courses free?",
      answer: 'All the courses featured on Futuro can be unlocked via Nexus, a virtual currency that can be earned purchased directly in our application or gained through interacting in the community tab.\n\nThis gives you the ability to unlock you favorite course for free through networking and collaborating with other users. ',
    },
    {
      id: 5,
      question: "How is the quality of Futuro's courses ensured?",
      answer: "To ensure the quality of the courses featured in Futuro, we implemented a system that only allows Verified Creators to create courses in our platform. \n\nVerified Creators are a group of industry experts in dozens of fields who have been carefully vetted and approved by Futuro. This eliminates the guesswork and uncertainty when it comes to selecting reliable and credible courses.",
    },
    {
      id: 6,
      question: "What topics are covered in Futuro's courses?",
      answer: "In Futuro, you can find courses featuring topics such as Trading, Coding, E-Commerce, Cybersecurity, Marketing, Design, Entrepeneurship and so much more.\n\n Our goal is to provide courses that are compatible to the market's current demands and empower our users with the knowledge and skills necessary to thrive in today's rapidly evolving world. "
    },
    {
      id: 7,
      question: 'How is Futuro any different from modern educational tools?',
      answer: 'Firstly, Futuro stands out from modern educational tools by addressing the issue of mindless scrolling and distractions that are prevalent on many social media platforms. By providing a safe and productive alternative, Futuro offers a platform that combines social networking with learning opportunities. This unique approach allows users to connect and collaborate while also focusing on educational growth. \n\nIn addition to that, Futuro distinguishes itself through its curated selection of courses taught by certified industry experts. The platform carefully vets and approves these Verified Creators, ensuring that users have access to high-quality and reliable educational content. This focus on expertise sets Futuro apart by offering a level of credibility and assurance in the courses available, all power by Artificial Intelligence.',
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleAnswers = (index) => {
    setActiveIndexs(activeIndexs === index ? null : index);
  };





  return (
    <div>
    <section className="bg-black h-full common-padding relative overflow-hidden">
      <div className="screen-max-wdith">
        
        <div className="flex flex-col justify-center items-center overflow-hidden">
          <div className="mt-32 mb-24 pl-55">
          <h2 style={{fontFamily: "YES"}} className="text-5xl lg:text-7xl font-semibold">Say <span style={{ color: '#00FFFF' }}>no</span></h2>
            <h2 style={{fontFamily: "YES"}} className="text-5xl lg:text-7xl font-semibold">to dopamine, <br></br>to time waste </h2>
                        <br></br>
            <br></br>

            <p className="text-white text-center heio">
            Embrace productivity and interactive learning with Futuro. <br className='desktop-only' ></br> Join our community of motivated teens today, <br className='desktop-only' ></br> unlock a world of networking opportunities. <br className="desktop-only" />
  <br /> </p>
          </div>


          <div className="flex-center flex-col sm:px-10">
            <img src={imggImg} className="heeyy object-cover object-center" />

            <div className="flex flex-col w-full relative">
              <div className="feature-video-container">
              </div>
              <div className='space'></div>
          <div className='space'></div>
              <div className="feature-texto-container">
              <div className="flex-1 flex-center">



              

              </div>



              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    </div>
  )
}
export default Features