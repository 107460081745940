import { appleImg, bagImg, searchImg } from '../utils';
import { navListss, navreff } from '../constants';

const Footer = () => {
  return (
    <div className='bg-trans'>

      <header className="w-full py-5 sm:px-10 px-5 flex justify-between items-center">
        <nav className="flex w-full screen-max-width">
          <div className="flex flex-1 justify-center">
  
             
  <a href="https://www.getfuturo.com">
    <img src="/assets/images/logo.png" alt="Apple" width={100} height={100} />
  </a>    
            
          </div>

        </nav>
      </header>
    </div>
  );
};

export default Footer
