import {useState, React} from 'react';
import Hero from './Hero';
import Highlights from './Highlights';
import Model from './Model';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Footer from './Footer';
import { emailImg } from '../utils';
import Navbar from './Navbar';
import { arrowImg } from '../utils';


const Help = () => {


  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    {
      id: 1,
      question: 'What is Futuro?',
      answer: "Futuro is an emerging and reimagined social media platform that empowers the teens of today to bring their ideas and vision into reality.\n\nOur mission is to create a platform for our generation that differs from modern social media platforms. Through courses, a community tab and job listings, we created the ultimate productivity platform, in order to eliminate instant dopamine and time waste.",
    },
    {
      id: 2,
      question: 'What can i find in Futuro?',
      answer: 'With Futuro, you can access a wide range of features such as courses created by Verified Creators, Job listings, which allows our users to monetize their skills. \n\nWe also feature a community tab for users to collaborate, interact and network with others that are in the same path and share the same passion for success.',
    },
    {
      id: 3,
      question: 'Is Futuro available to use right now?',
      answer: 'At this stage, Futuro is under development as we prepare the core features for our platforms. We project to release a BETA for Futuro at the end of 2024.\n\nMeanwhile, you can sign up to our free waiting list below to be notified on when you can experience Futuro.',
    },
    {
      id: 4,
      question: "Are Futuro's courses free?",
      answer: 'All the courses featured on Futuro can be unlocked via Nexus, a virtual currency that can be earned purchased directly in our application or gained through interacting in the community tab.\n\nThis gives you the ability to unlock you favorite course for free through networking and collaborating with other users. ',
    },
    {
      id: 5,
      question: "How is the quality of Futuro's courses ensured?",
      answer: "To ensure the quality of the courses featured in Futuro, we implemented a system that only allows Verified Creators to create courses in our platform. \n\nVerified Creators are a group of industry experts in dozens of fields who have been carefully vetted and approved by Futuro. This eliminates the guesswork and uncertainty when it comes to selecting reliable and credible courses.",
    },
    {
      id: 6,
      question: "What topics are covered in Futuro's courses?",
      answer: "In Futuro, you can find courses featuring topics such as Trading, Coding, E-Commerce, Cybersecurity, Marketing, Design, Entrepeneurship and so much more.\n\n Our goal is to provide courses that are compatible to the market's current demands and empower our users with the knowledge and skills necessary to thrive in today's rapidly evolving world. "
    },
    {
      id: 7,
      question: 'How is Futuro any different from modern educational tools?',
      answer: 'Firstly, Futuro stands out from modern educational tools by addressing the issue of mindless scrolling and distractions that are prevalent on many social media platforms. By providing a safe and productive alternative, Futuro offers a platform that combines social networking with learning opportunities. This unique approach allows users to connect and collaborate while also focusing on educational growth. \n\nIn addition to that, Futuro distinguishes itself through its curated selection of courses taught by certified industry experts. The platform carefully vets and approves these Verified Creators, ensuring that users have access to high-quality and reliable educational content. This focus on expertise sets Futuro apart by offering a level of credibility and assurance in the courses available, all power by Artificial Intelligence.',
    },
  ];

  const questionss = [
    {
      id: 1,
      question: "How can i become a Verified Creator?",
      answer: 'At this time, Verified Creators are selected by our team based on multiple skillsets and strict criterias in order to ensure the quality and expertise of the creators on Futuro. \n\nIf you are interested in becoming a Verified Creator in our platform, then we recommend reaching out to us directly. ',
    },
    {
      id: 2,
      question: "How can I create and upload my courses on Futuro?",
      answer: "As a Verified Creator in Futuro, a dashboard will be available to you in the application, where you will be able to manage your courses, edit them and check it's statistics based on the number of unlocks, the amount of shares for each course and your total revenue.",
    },
    {
      id: 3,
      question: "How does the monetization system work on Futuro?",
      answer: "Based on the amount of sales you get, you will earn a certain percentage of the Nexus, which is Futuro's virtual currency, spent to unlock the course. Once you earn enough Nexus to make your first withdrawal, you will be able to convert Nexus to FIAT based on an exchange rate and your payment will be processed via the available processors. "
    },
    {
      id: 4,
      question: 'How can I interact and communicate with my enrolled students?',
      answer: 'To interact and communicate with the users enrolled in your course, you can access your dashboard, head over to your course and click the members section, in which you can see a list of all the members enrolled. This allows you to privately message your students and communicate together.',
    },
    {
      id: 5,
      question: 'Can I offer discounts or promotional offers for my courses?',
      answer: 'Verified Creators can either upload courses that can be unlocked via Nexus, our virtual currency, or they can create a Nexus subscription, that grants X amount of courses to the subscribed members on a weekly or monthly basis.',
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  const [activeButton, setActiveButton] = useState('learner');

  const toggleContent = (button) => {
    setActiveButton(button);
  };

  return (
    <section   style={{
      backgroundImage: 'url(assets/images/bg.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: '100%',
      height: '1650px',
    }} >
       <Navbar/>
        <div

>       
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>



  <p id="hero" style={{ fontSize: "50px" }} className="hero-title">
    How may we help you <span style={{ color: '#00FFFF' }}>today?</span>
  </p>
  <div className='space'></div>
        <div className='space'></div>

<div className='somethigs'>
<button
          className={`toggle-buttonzz ${activeButton === 'learner' ? 'active' : ''}`}
          onClick={() => toggleContent('learner')}
        >
          {'Learner'}
        </button>
        
        <button
          className={`toggle-buttonzzz ${activeButton === 'creator' ? 'active' : ''}`}
          onClick={() => toggleContent('creator')}
        >
          {'Creator'}
        </button>
        </div>

      <div className="content-containerzz">
      {activeButton === 'learner' ? (
          <div className="content-block">
                    <div className='space'>      
                    </div>

                    <div className="flex flex-col justify-center items-center overflow-hidden">
<div className="faq-container">
  <h2 style={{fontSize: "30px"}} className="faq-title">Frequently Asked Questions</h2>
  <div className="faq-list">
    {questions.map((q, index) => (
      <div
        key={q.id}
        className={`faq-item ${activeIndex === index ? 'active' : ''}`}
        onClick={() => toggleAnswer(index)}
      >
        <div className="question">{q.question}</div>
        <img
          className={`arrow-iconnn ${activeIndex === index ? 'open' : ''}`}
          src={arrowImg}
          alt="Arrow"
          style={{ transform: activeIndex === index ? 'rotate(90deg)' : 'rotate(0)' }}
        />
        {activeIndex === index && <div className="answer">{q.answer}</div>}
      </div>
      
    ))}

      <div className='space'></div>
  <div className='space'></div>
      <h1 style={{fontSize: "30px"}} id="title" className="hero-title">Still need assistance? don't hesitate to <br></br> contact us at: <a href='mailto:business@getfuturo.com' ><span  style={{ color: '#00FFFF' }}>business@getfuturo.com </span> </a> </h1>

  </div>


</div>
</div>


          </div>
        ) : (
          <div className="content-block">
                    <div className='space'></div>


                    <div className="flex flex-col justify-center items-center overflow-hidden">
<div className="faq-container">
<h2 style={{fontSize: "30px"}} className="faq-title">Frequently Asked Questions</h2>
  <div className="faq-list">
        {questionss.map((q, index) => (
      <div
        key={q.id}
        className={`faq-item ${activeIndex === index ? 'active' : ''}`}
        onClick={() => toggleAnswer(index)}
      >
        <div className="question">{q.question}</div>
        <img
          className={`arrow-iconnn ${activeIndex === index ? 'open' : ''}`}
          src={arrowImg}
          alt="Arrow"
          style={{ transform: activeIndex === index ? 'rotate(90deg)' : 'rotate(0)' }}
        />
        {activeIndex === index && <div className="answer">{q.answer}</div>}
      </div>
    ))}
      <div className='space'></div>
  <div className='space'></div>
  <h1 style={{fontSize: "30px"}} id="title" className="hero-title">Still need assistance? don't hesitate to <br></br> contact us at: <a href='mailto:business@getfuturo.com' ><span  style={{ color: '#00FFFF' }}>business@getfuturo.com </span> </a> </h1>

  </div>
</div>
</div>

          </div>
        )}
                </div>

      </div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>
        <div className='space'></div>



    </section>
  );
};



export default Help;

