import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { heroVideo, smallHeroVideo, communityImg } from '../utils';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { adVideo } from "../utils";
import ReCAPTCHA from 'react-google-recaptcha';
import { arrowImg } from '../utils';
import emailjs from '@emailjs/browser';






const Hero = () => {

  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const recaptchaRef = useRef();

  const handleButtonClick = () => {
    setShowRecaptcha(true);
  };

  const handleRecaptchaChange = () => {
    // Handle reCAPTCHA change event here
  };




  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const emailValue = form.current.user_email.value;
    const nameValue = form.current.user_email.value;
    const messageValue = "Hello! Thank you so much for your interest in Futuro. During this stage of development, it is important that we make sure Futuro is tailored to it's best shape. Meanwhile, keep an eye on our website as we will constantly update it with news on the development of our product as well as new features!  ";

    const serviceId = 'service_tuib68k';
    
    const templateId = 'template_irzlb3i';

    const token = recaptchaRef.current.getValue();     
    
    const publicKey = '-LjV2JFPrYRftk1I6';


    const templateParams = {
         from_name: nameValue,
         from_email: "business@getfuturo.com",
         to_name: emailValue,
         message: messageValue,
         to_mail: emailValue,
      'g-recaptcha-response': token, // Include the reCAPTCHA token
    };

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then(
        () => {
          setName('');
          setEmail('');
          setMessage('');
        },
        (error) => {
        }
      );
  };




























  const videoRef = useRef(null);
  const [showResumeIcon, setShowResumeIcon] = useState(true);

  const handleVideoClick = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setShowResumeIcon(false);
    } else {
      videoRef.current.pause();
      setShowResumeIcon(true);
    }
  };







  
  const [videoSrc, setVideoSrc] = useState(window.innerWidth < 760 ? smallHeroVideo : heroVideo)

  const handleVideoSrcSet = () => {
    if(window.innerWidth < 760) {
      setVideoSrc(smallHeroVideo)
    } else {
      setVideoSrc(heroVideo)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleVideoSrcSet);

    return () => {
      window.removeEventListener('reisze', handleVideoSrcSet)
    }
  }, [])

  useGSAP(() => {
    gsap.to('#hero', { opacity: 1, delay: 2 })
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 })
  }, [])


  return (
<div>

<section class="bg-blackk custom-width nav-height relative">
  
  <div class="space"></div>
  <div class="space"></div>
  <div class="space"></div>

  <p class="hero-titlepp">Stop {''}


  <span className='fade-in'
  style={{
    position: 'relative',
    display: 'inline-block',
  }}
>
  wasting
  <span
    style={{
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: '5px',
      background: "linear-gradient(to right, transparent, aqua, #00cccc, transparent)",
      animation: 'glow 1s ease-in-out infinite',
    }}
  ></span>
</span>






 <br className='desktop-only'></br>
<br className='only-mobile' ></br> your  time <br className='desktop-only'></br> mindlessly <span> scrolling.</span></p>
  <p style={{fontFamily: "YES", color: "white"}} className="hiw-subtitle">
            Experience free <br className='only-mobile' ></br> courses tailored by experts and a community of determined teens <br className='only-mobile' ></br> all in one app.
          </p>



          <div className='spacetwo'></div>










          <div style={{ marginTop: '-50px' }}>

<form ref={form} onSubmit={sendEmail} className='form'>


  
  
<div className='input-wrapper'>
  <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: '10px', alignItems: 'center' }}>
    <input
      type='email'
      name='user_email'
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      placeholder='Your Email Address:'
      onFocus={(e) => (e.target.placeholder = '')}
      onBlur={(e) => (e.target.placeholder = 'Your Email Address:')}
      className='input'
    />



    <a href="https://getfuturo.com/features" rel="noopener noreferrer">
      <button type='submit' style={{ fontFamily: "YES" }} className="sleekz-button"onClick={handleButtonClick}>  
        Get Started
      </button>
    </a>
  </div>







<div className='spacemini'></div>

<div style={{color: "white", marginTop: "5px", fontFamily: "YES"}} className='px-2 text-sm cursor-pointer text-gray hover:text-white transition-all'> By signing up, you agree to our {''}
<a  style={{  textDecoration: 'underline', fontFamily: "YES" }} href='/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a> and {''}
<a  style={{  textDecoration: 'underline', fontFamily: "YES" }} href='/policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
</div>
<div className='space'></div>



{showRecaptcha && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey='6LfBbcQpAAAAACBlfC6x6YrfPIdeIB_nUyyMQgV9'
          onChange={handleRecaptchaChange}
          className='captcha'
        />
      )}



<div style={{ position: 'relative' }}>
      <video
        ref={videoRef}
        playsInline
        className="pointer-events-none"
        preload="auto"
        muted
        loop
        onClick={handleVideoClick}
      >
        <source src={adVideo} type="video/mp4" />
      </video>

      {showResumeIcon && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faPlay}
            size="3x"
            style={{ cursor: 'pointer' }}
            onClick={handleVideoClick}
          />
        </div>
      )}
    </div>



  </div>
  
</form>





<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>
<div class="baitspace"></div>







</div>








          
</section>
</div>
  )
}

export default Hero